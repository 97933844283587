import DisplayTable from '../components/ghana/DisplayTablev2';


import airteltigoLogo from '../assets/airteltigo_gha.png';
import mtnLogo from '../assets/mtn_momo_gha.png';
import vodafoneLogo from '../assets/vodafone_gha.png';

function Winners() {
  const HubtelPaymentFormProps = {
    currency: "GHS",
    currencyOperator: "GHS",
    countrycode: 233,
    countryName: "Ghana",
    minAmount: 5,
    maxAmount: 2000,
    platform: "WEB",
    serviceCode: "2000",
    winuptoAmount: "500,000",
    validPrefixes: ["02", "05"],
    operators: [
      { id: "AirtelTigo", name: "AirtelTigo", logo: airteltigoLogo },
      { id: "MTN", name: "MTN", logo: mtnLogo },
      { id: "Telecel", name: "Telecel", logo: vodafoneLogo },
    ],
    operatorValues: {
      AirtelTigo: "AT",
      MTN: "MTN",
      Telecel: "TELECEL",
    },
  };

  return (
    <>
     
      <div>
        <div className=" mt-24 rounded-lg   mx-4 md:mx-20  my-10  border-2 border-blue-950  ">
          <DisplayTable
            countryCode={HubtelPaymentFormProps.countrycode.toString()}
            currency={HubtelPaymentFormProps.currency}
            firstDigits={HubtelPaymentFormProps.validPrefixes.map(Number)}
            minBetAmount={100}
            maxBetAmount={200}
            //minBetAmount={HubtelPaymentFormProps.minAmount}
           //maxBetAmount={HubtelPaymentFormProps.maxAmount}
            count={14}
            tableHeading="Top Recent Winners"
          />
        </div>
      </div>
     
    </>
  );
}

export default Winners;