import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
//import Intercom from '@intercom/messenger-js-sdk';

import HubtelPaymentForm from '../components/ghana/FormBox';
import DisplayTable from '../components/ghana/DisplayTablev2';
import ImageCarousel from '../components/ghana/ImageCarousel';
import PrizeTable from '../components/ghana/PrizesTablev2';
import Table from '../components/ghana/Table';

import airteltigoLogo from '../assets/airteltigo_gha.png';
import mtnLogo from '../assets/MTN-MOMO.png';
import vodafoneLogo from '../assets/Telecel.svg';

const DEFAULT_REFERRAL_CODE = "2000"; // Default code without '#'

function GhPageHub({ age }) {  // Accept age as a prop
  const [referralCode, setReferralCode] = useState(DEFAULT_REFERRAL_CODE);
  const location = useLocation();

  // Intercom({
  //   app_id: 'xv3db0op',
  // });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let referral = params.get("referral");

    if (referral) {
      // Decode the referral code if it's URL-encoded
      try {
        referral = decodeURIComponent(referral);
      } catch (error) {
        console.error("Error decoding referral code:", error);
        referral = DEFAULT_REFERRAL_CODE;
      }

      // If referral starts with '*', ensure it ends with '#'
      if (referral.startsWith('*') && !referral.endsWith('#')) {
        referral += '#';
      }

      setReferralCode(referral);
    } else {
      setReferralCode(DEFAULT_REFERRAL_CODE);
    }

    console.log("Referral Code:", referral || DEFAULT_REFERRAL_CODE);
  }, [location.search]);

  const HubtelPaymentFormProps = {
    currency: "GHS",
    currencyOperator: "GHS",
    countrycode: 233,
    countryName: "Ghana",
    minAmount: 5,
    maxAmount: 2000,
    platform: "WEB",
    serviceCode: referralCode,
    winuptoAmount: "500,000",
    validPrefixes: ["02", "05"],
    operators: [
      { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
      { id: 'MTN', name: 'MTN', logo: mtnLogo },
      { id: 'Telecel', name: 'Telecel', logo: vodafoneLogo }
    ],
    operatorValues: {
      "AirtelTigo": "AT",
      "MTN": "MTN",
      "Telecel": "TELECEL"
    },
    age,  // Pass the verified age to the payment form
    referralCode
  };

  return (
    <div>
      <div className="w-full pt-28 font-body">
        <div className="flex flex-col md:flex-row mb-4 ">
          <HubtelPaymentForm {...HubtelPaymentFormProps} />
          <Table className="lg:w-1/2 border-2 border-blue-950 rounded-lg shadow-lg md:mr-14 mx-2 md:mt-0 mt-8">
            <DisplayTable
              countryCode={HubtelPaymentFormProps.countrycode.toString()}
              currency={HubtelPaymentFormProps.currency}
              firstDigits={HubtelPaymentFormProps.validPrefixes.map(Number)}
              minBetAmount={100}
              maxBetAmount={200}
              dateFormat="GH" 
              count={18}
              tableHeading="Top Recent Winners"
            />
          </Table>
        </div>
        <ImageCarousel />
        <Table className="flex flex-col justify-center my-5 p-2">
          <PrizeTable />
        </Table>
      </div>
    </div>
  );
}

export default GhPageHub;


// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";

// import HubtelPaymentForm from '../components/ghana/FormBox';
// import DisplayTable from '../components/ghana/DisplayTablev2';
// import ImageCarousel from '../components/ghana/ImageCarousel';
// import PrizeTable from '../components/ghana/PrizesTablev2';
// import Table from '../components/ghana/Table';

// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/MTN-MOMO.png';
// import vodafoneLogo from '../assets/Telecel.svg';

// const DEFAULT_REFERRAL_CODE = "2000"; // Default code without '#'

// function GhPageHub({ age }) {  // Accept age as a prop
//   const [referralCode, setReferralCode] = useState(DEFAULT_REFERRAL_CODE);
//   const location = useLocation();

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     let referral = params.get("referral");

//     if (referral) {
//       // Check if there's a hash in the URL
//       if (location.hash === '#') {
//         // Append '#' only if the hash is exactly '#'
//         referral += '#';
//       }
//       setReferralCode(referral);
//     } else {
//       setReferralCode(DEFAULT_REFERRAL_CODE);
//     }

//     console.log("Referral Code:", referral || DEFAULT_REFERRAL_CODE);
//   }, [location.search, location.hash]);

//   const HubtelPaymentFormProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     countryName: "Ghana",
//     minAmount: 5,
//     maxAmount: 2000,
//     platform: "WEB",
//     serviceCode: referralCode,
//     winuptoAmount: "500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Telecel', name: 'Telecel', logo: vodafoneLogo }
//     ],
//     operatorValues: {
//       "AirtelTigo": "AT",
//       "MTN": "MTN",
//       "Telecel": "TELECEL"
//     },
//     age,  // Pass the verified age to the payment form
//     referralCode
//   };

//   return (
//     <div>
//       <div className="w-full pt-28 font-body">
//         <div className="flex flex-col md:flex-row mb-4 ">
//           <HubtelPaymentForm {...HubtelPaymentFormProps} />
//           <Table className="lg:w-1/2 border-2 border-blue-950 rounded-lg shadow-lg md:mr-14 mx-2 md:mt-0 mt-8">
//             <DisplayTable
//               countryCode={HubtelPaymentFormProps.countrycode.toString()}
//               currency={HubtelPaymentFormProps.currency}
//               firstDigits={HubtelPaymentFormProps.validPrefixes.map(Number)}
//               minBetAmount={100}
//               maxBetAmount={200}
//               count={18}
//               tableHeading="Top Recent Winners"
//             />
//           </Table>
//         </div>
//         <ImageCarousel />
//         <Table className="flex flex-col justify-center my-5 p-2">
//           <PrizeTable />
//         </Table>
//       </div>
//     </div>
//   );
// }

// export default GhPageHub;


// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";

// import HubtelPaymentForm from '../components/ghana/FormBox';
// import DisplayTable from '../components/ghana/DisplayTablev2';
// import ImageCarousel from '../components/ghana/ImageCarousel';
// import PrizeTable from '../components/ghana/PrizesTablev2';
// import Table from '../components/ghana/Table';

// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/MTN-MOMO.png';
// import vodafoneLogo from '../assets/Telecel.svg';

// const DEFAULT_REFERRAL_CODE = "2000";

// function GhPageHub({ age }) {  // Accept age as a prop
//   //onsole.log("age",age)
//   const [referralCode, setReferralCode] = useState(DEFAULT_REFERRAL_CODE);
//   const location = useLocation();

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const referral = params.get("referral");
//     if (referral) {
//       setReferralCode(referral);
//     }

//     console.log("Referral Code:", referral || DEFAULT_REFERRAL_CODE);
//   }, [location.search]);

//   const HubtelPaymentFormProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     countryName: "Ghana",
//     minAmount: 5,
//     maxAmount: 2000,
//     platform: "WEB",
//     serviceCode: referralCode,
//     winuptoAmount: "500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Telecel', name: 'Telecel', logo: vodafoneLogo }
//     ],
//     operatorValues: {
//       "AirtelTigo": "AT",
//       "MTN": "MTN",
//       "Telecel": "TELECEL"
//     },
//     age,  // Pass the verified age to the payment form
//     referralCode
//   };

//   return (
//     <div>
      
//       <div className="w-full pt-28 font-body">
//         <div className="flex flex-col md:flex-row mb-4 ">
//           <HubtelPaymentForm {...HubtelPaymentFormProps} />
//           <Table className="lg:w-1/2 border-2 border-blue-950 rounded-lg shadow-lg md:mr-14 mx-2 md:mt-0 mt-8">
//             <DisplayTable
//               countryCode={HubtelPaymentFormProps.countrycode.toString()}
//               currency={HubtelPaymentFormProps.currency}
//               firstDigits={HubtelPaymentFormProps.validPrefixes.map(Number)}
//               // minBetAmount={HubtelPaymentFormProps.minAmount}
//               // maxBetAmount={HubtelPaymentFormProps.maxAmount}
//                minBetAmount={100}
//               maxBetAmount={200}
//               count={18}
//               tableHeading="Top Recent Winners"
//             />
//           </Table>
//         </div>
//         <ImageCarousel />
//         <Table className="flex flex-col justify-center my-5 p-2">
//           <PrizeTable />
//         </Table>
//       </div>
     
//     </div>
//   );
// }

// export default GhPageHub;



// import React, { useEffect, useState, useCallback } from "react";
// import { useLocation } from "react-router-dom";
// import Nav from '../components/ghana/Nav';
// import HubtelPaymentForm from '../components/ghana/FormBox';
// import DisplayTable from '../components/ghana/DisplayTablev2';
// import ImageCarousel from '../components/ghana/ImageCarousel';
// import PrizeTable from '../components/ghana/PrizesTablev2';
// import Table from '../components/ghana/Table';
// import Footer from '../components/ghana/footer';
// import airteltigoLogo from '../assets/airteltigo_gha.png';
// import mtnLogo from '../assets/mtn_momo_gha.png';
// import swal from 'sweetalert2';
// import vodafoneLogo from '../assets/vodafone_gha.png';
// import LoadingSpinner from '../components/ghana/LoadingSpinner';




// const DEFAULT_REFERRAL_CODE = "2000";
// const MIN_AGE = 18;


// function GhPageHub() {

//   const [ageVerified, setAgeVerified] = useState(false);
//   const [age, setAge] = useState(null);
//   const [referralCode, setReferralCode] = useState(DEFAULT_REFERRAL_CODE);
//   const [isLoading, setIsLoading] = useState(true);
//   const location = useLocation();




//   const checkAgeVerification = useCallback(async () => {
//     const storedAgeVerified = localStorage.getItem('ageVerified');
//     const storedAge = localStorage.getItem('age');
    

//     if (storedAgeVerified && storedAge) {
//       setAgeVerified(true);
//       setAge(parseInt(storedAge, 10));
//       return;
//     }

//     const { value: inputAge } = await swal.fire({
//       title: 'Age Verification',
//       text: `You must be ${MIN_AGE} or older to access this site.`,
//       input: 'number',
//       inputAttributes: {
//         placeholder: 'Enter your age',
//         min: 0,
//         max: 99,
//         step: 1
//       },
//       confirmButtonColor: '#0000ff',
//       showCancelButton: false,
//       confirmButtonText: 'Continue',
//       allowOutsideClick: false,
//       allowEscapeKey: false,
//       preConfirm: (age) => {
//         if (age < MIN_AGE) {
//           swal.showValidationMessage(`You must be ${MIN_AGE} or older to access this site.`);
//         }
//         return age;
//       }
//     });

//     if (inputAge >= MIN_AGE) {
//       localStorage.setItem('ageVerified', 'true');
//       localStorage.setItem('age', inputAge.toString());
//       setAge(inputAge);
//       setAgeVerified(true);
//     } else {
//       swal.fire('Access Denied', `You must be ${MIN_AGE} or older to access this site.`, 'error');
//     }
//   }, []);

//   useEffect(() => {
//     const params = new URLSearchParams(location.search);
//     const referral = params.get("referral");
//     if (referral) {
//       setReferralCode(referral);
//     }

//     console.log("Referral Code:", referral || DEFAULT_REFERRAL_CODE);

//     if (!ageVerified) {
//       checkAgeVerification();
//     }
//   }, [ageVerified, location.search, checkAgeVerification]);

//   if (!ageVerified) {
//     return (
//       <div style={{ textAlign: 'center' }}>
//         <h2>Age Verification Pending...</h2>
//         <LoadingSpinner />
//       </div>
//     );
//   }

 

//   const HubtelPaymentFormProps = {
//     currency: "GHS",
//     currencyOperator: "GHS",
//     countrycode: 233,
//     countryName: "Ghana",
//     minAmount: 5,
//     maxAmount: 2000,
//     platform: "WEB",
//     serviceCode: referralCode,
//     winuptoAmount: "500,000",
//     validPrefixes: ["02", "05"],
//     operators: [
//       { id: 'AirtelTigo', name: 'AirtelTigo', logo: airteltigoLogo },
//       { id: 'MTN', name: 'MTN', logo: mtnLogo },
//       { id: 'Telecel', name: 'Telecel', logo: vodafoneLogo }
//     ],
//     operatorValues: {
//       "AirtelTigo": "AT",
//       "MTN": "MTN",
//       "Telecel": "TELECEL"
//     },
//     age,
//     referralCode
//   };

//   return (
//     <div>
//       <Nav />
//       <div className="w-full pt-28 font-body">
//         <div className="flex flex-col md:flex-row mb-4">
//           <HubtelPaymentForm {...HubtelPaymentFormProps} />
//           <Table className="lg:w-1/2 border-2 border-blue-950 rounded-lg shadow-lg md:mr-14 mx-8 md:mt-0 mt-8">
//             <DisplayTable
//               countryCode={HubtelPaymentFormProps.countrycode.toString()}
//               currency={HubtelPaymentFormProps.currency}
//               firstDigits={HubtelPaymentFormProps.validPrefixes.map(Number)}
//               minBetAmount={HubtelPaymentFormProps.minAmount}
//               maxBetAmount={HubtelPaymentFormProps.maxAmount}
//               count={20}
//               tableHeading="Top Recent Winners"
//             />
//           </Table>
//         </div>
//         <ImageCarousel />
//         <Table className="flex flex-col justify-center my-5 p-5">
//           <PrizeTable />
//         </Table>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default GhPageHub;


